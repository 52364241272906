<template>
  <!-- eslint-disable -->
   <section>
      <b-card class="mb-3">
         <h3 class="mb-2">
            Bonus Overview
            <h5>
               <span v-b-tooltip.hover.top="'The bonus is calculated based on the total amount of paid invoices.'">Current
                  total amount: {{ formatCurrency(bonus.total_amount) }}</span>
            </h5>
         </h3>

         <b-row>
            <b-col v-for="stage in bonusStages" :key="stage.percent" lg="4">
               <b-card :class="{
                  'text-white bold bg-primary': isCurrentStage(stage),
                  'text-noir bold bg-light': !isCurrentStage(stage),
               }" class="text-center mb-2">
                  <h5 :class="{
                     'text-white bold': isCurrentStage(stage),
                     'text-noir bold': !isCurrentStage(stage),
                  }">
                     {{ stage.label }}€
                  </h5>

                  <p>Bonus Percentage: {{ stage.percent }}%</p>

                  <!-- Current Stage -->
                  <p v-if="isCurrentStage(stage)">
                     CURRENT STAGE<br />
                     Bonus: {{ formatCurrency(bonus.bonus_amount) }}
                  </p>

                  <!-- Future Stage: Show the bonus range -->
                  <p v-else-if="isFutureStage(stage)">
                     UPCOMING STAGE<br />
                     Potential Bonus: {{ formatCurrency(calculateBonus(stage.min)) }} -
                     {{ formatCurrency(calculateBonus(stage.max)) }}
                  </p>

                  <!-- Completed Stage: Show the actual bonus earned -->
                  <p v-else>
                     COMPLETED STAGE<br />
                     Past Maximum Bonus: {{ formatCurrency(calculateBonus(stage.max)) }}
                  </p>
               </b-card>
            </b-col>
         </b-row>
      </b-card>

      <b-row style="margin-bottom: 25px;">
         <b-col sm="3" style="margin-bottom: 4px;">
            <b-form-select v-model="thisMonth" @change="monthChange()">
               <b-form-select-option v-for="month in prevMonths" :key="month.value" :value="month.value">
                  {{ month.name }}
               </b-form-select-option>
            </b-form-select>
         </b-col>

         <b-col sm="3" style="margin-bottom: 4px;">
            <b-form-select v-model="selectedFilter">
               <b-form-select-option v-for="filter in ticketFilters" :key="filter.value" :value="filter.value">
                  {{ filter.text }}
               </b-form-select-option>
            </b-form-select>
         </b-col>

         <!-- Clear Button aligned to the right of the month selector -->
         <b-col sm="2" style="margin-bottom: 4px;">
            <b-button variant="primary" @click="clearFilters" class="w-100">
               Clear Filters
            </b-button>
         </b-col>
      </b-row>
      <div v-if="stats.length" class="home-wrapper">
         <b-row>
            <b-col v-for="(statistic, index) in stats" :key="index" lg="3" sm="6">
               <b-card class="cursor-pointer" no-body @click="openStatistics(statistic.admin.id)">
                  <b-card-body class="d-flex justify-content-between align-items-center">
                     <div class="truncate">
                        <h4 class="mb-25 font-weight-bolder">
                           {{ statistic.admin.forename }}
                           {{ statistic.admin.surname }}
                        </h4>
                        <div v-if="index < 3">
                           <b-badge
                              :variant="index === 0 ? 'success' : index === 1 ? 'primary' : index === 2 ? 'info' : 'secondary'"
                              class="" pill>
                              {{ index === 0 ? '1st' : index === 1 ? '2nd' : '3rd' }} Place
                           </b-badge>
                           <br>
                           <small class="font-weight-bold">
                              Bonus: {{ formatCurrency(calculateAdminBonus(index)) }}
                              <br>
                              <small>({{ adminShare[index] }}% of total bonus)</small>
                           </small>
                        </div>
                        <div style="margin-top: 5px;">
                           <b-badge variant="light-primary" v-b-tooltip.hover.top="'Total points earned from tickets'
                              ">
                              {{ statistic.total_points }} point(s)
                           </b-badge>
                           <b-badge variant="light-info" class="ml-1" v-b-tooltip.hover.top="'Total earned from custom developments and bonus if any'
                              ">
                              €

                              {{
                                 computeTotalComission(statistic.custom_integrations_partial_sum,
                                    calculateAdminBonus(index))
                              }}
                              commission
                           </b-badge>
                        </div>
                        <div style="margin-top: 5px;">
                           <b-badge variant="light-warning" v-b-tooltip.hover.top="'Total number of tickets solved'
                              ">
                              {{ statistic.solved_tickets_count }} solved
                              ticket(s)
                           </b-badge>
                           <b-badge variant="light-success" class="ml-1"
                              v-b-tooltip.hover.top="'Total number of pending comissions (invoice not paid yet)'">
                              € {{ statistic.custom_integrations_pending_sum }} pending
                           </b-badge>
                        </div>
                     </div>
                     <b-avatar v-if="statistic.admin.avatarUrl" :src="statistic.admin.avatarUrl" size="45"
                        style="border: 1.5px solid rgba(194, 63, 141, 0.42);" />
                     <b-avatar v-else variant="light-primary" size="45">
                        <feather-icon icon="UserIcon" size="20" />
                     </b-avatar>
                  </b-card-body>
               </b-card>
            </b-col>
         </b-row>

         <b-card v-if="selectedAdmin">
            <div v-if="!isLoading" class="invoice-register-table pt-1">
               <vue-good-table :columns="columns" :rows="tableData" ref="myTable" :search-options="{
                  enabled: true,
                  externalQuery: searchTerm,
               }" :pagination-options="{
                  enabled: true,
                  perPage: pageLength,
               }" :sort-options="sortOptions" @on-sort-change="handleSortChange" class="mt-0 mytable">
                  <template slot="table-row" slot-scope="props">
                     <span v-if="props.column.field === 'ticket_id'">
                        <router-link :to="`/tickets/${props.row.ticket_id}`" class="font-weight-bold">
                           #{{ props.row.ticket_id }}<br />
                           <small>{{ props.row.ticket_name }}</small>
                        </router-link>
                     </span>

                     <span v-else-if="props.column.field === 'invoice_id'">
                        <router-link v-if="props.row.invoice_id" :to="`/invoices/${props.row.invoice_id}`"
                           class="font-weight-bold">
                           #{{ props.row.invoice_id }}
                        </router-link>
                        <span v-else>-</span>
                     </span>

                     <span v-else-if="props.column.field === 'paid_this_month'">
                        <template v-if="props.row.invoice_id">
                           <b-badge variant="light-success" v-if="props.row.paid_this_month">
                              Paid
                           </b-badge>
                           <b-badge variant="light-warning" v-else-if="props.row.paid_prev_month">
                              Paid Last Month
                           </b-badge>
                           <b-badge variant="light-danger" v-else>
                              Not Paid
                           </b-badge>
                        </template>
                        <span v-else>-</span>
                     </span>

                     <span v-else-if="props.column.field === 'id'">
                        {{ props.rowIndex }}
                     </span>

                     <span v-else-if="props.column.field === 'name'">
                        {{ props.row.name }}
                     </span>

                     <span v-else-if="props.column.field === 'points'">
                        <Stars v-b-tooltip.hover.top="'Ticket level'" :level="props.row.points" :just-show="true"
                           :key="props.row.ticket_id" :style="{
                              opacity: (props.row.solved_this_month && (props.row.ticket_status == 'closed' || props.row.ticket_status == 'permanent_closed'))
                                 ? 1
                                 : 0.5
                           }"
                           :grey-stars="!props.row.solved_this_month && (props.row.ticket_status != 'closed' || props.row.ticket_status != 'permanent_closed')">
                        </Stars>
                     </span>

                     <span v-else-if="props.column.field === 'ticket_status'">
                        <b-badge pill :variant="statusTagColor(props.row.ticket_status)">
                           {{ props.row.ticket_status }}
                        </b-badge>
                     </span>

                     <span v-else-if="props.column.field === 'team_member'">
                        {{ props.row.admin.forename }}
                        {{ props.row.admin.surname }}
                     </span>

                     <span class='text-white' v-else-if="props.column.field === 'secondary_status'"
                        :id="`popover-status-${props.row.ticket_id}`">
                        <b-badge v-if="!updateSecondaryStatus[props.row.ticket_id]"
                           :variant="getStatusVariant(props.row.secondary_status, props.row.ticket_status)"
                           style="cursor: pointer;">
                           <span class="text-white"> {{ props.row.secondary_status }}</span>
                           <i class="bi bi-caret-down-fill text-white" v-if="props.row.secondary_status != 'normal'" />
                        </b-badge>
                        <b-spinner v-else variant="primary" label="Loading..." small />

                        <!-- Status change popover -->
                        <b-popover v-if="!updateSecondaryStatus[props.row.ticket_id]" :disabled="props.row.name == '-'"
                           :target="`popover-status-${props.row.ticket_id}`" triggers="hover" placement="left">
                           <div style="margin: 10px">
                              <span v-show="!confirmStatusChangeStep">
                                 <strong>Change Status</strong>
                                 <div v-for="status in statusOptions" :key="status.value">
                                    <button v-if="
                                       props.row.secondary_status !==
                                       status.value
                                    " type="button" :class="[
                                       'btn btn-sm',
                                       `btn-${getStatusVariant(
                                          status.value
                                       )}`,
                                    ]" style="margin: 3px; width: 100%;" @click="
                                       askConfirmStatusChange(
                                          props.row,
                                          status.value
                                       )
                                       ">
                                       {{ status.text }}
                                    </button>
                                 </div>
                              </span>

                              <!-- Confirm step -->
                              <span v-show="confirmStatusChangeStep">
                                 <strong>Are you sure?</strong>
                                 <button type="button" class="btn btn-outline-primary btn-sm"
                                    style="margin: 3px; width: 100%;" @click="confirmStatusChange">
                                    Yes
                                 </button>
                                 <button type="button" class="btn btn-outline-danger btn-sm"
                                    style="margin: 3px; width: 100%;" @click="cancelStatusChange">
                                    No
                                 </button>
                              </span>
                           </div>
                        </b-popover>
                     </span>

                     <span v-else-if="props.column.field === 'price'">
                        <b-badge :variant="getStatusVariant(props.row.secondary_status, props.row.ticket_status)">
                           <span class="text-white">€{{ props.row.price }}</span>
                        </b-badge>
                     </span>

                     <span v-else-if="props.column.field === 'commission'">
                        <b-badge :variant="getStatusVariant(props.row.secondary_status, props.row.ticket_status)">
                           <span class="text-white">€{{ props.row.commission }}</span>
                        </b-badge>
                     </span>
                  </template>

                  <!-- pagination -->
                  <template slot="pagination-bottom" slot-scope="props">
                     <div class="d-flex justify-content-between flex-wrap mt-1">
                        <div class="d-flex align-items-center mb-0 mt-1">
                           <span class="text-nowrap ">
                              Showing 1 to
                           </span>
                           <b-form-select v-model="pageLength" :options="['10', '25', '50']" style="margin: 0 5px;"
                              @input="(value) =>
                                 props.perPageChanged({
                                    currentPerPage: value,
                                 })
                                 " />
                           <span class="text-nowrap">
                              of {{ props.total }} entries
                           </span>
                        </div>
                        <div>
                           <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                              last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                              @input="(value) =>
                                 props.pageChanged({ currentPage: value })
                                 ">
                              <template #prev-text>
                                 <feather-icon icon="ChevronLeftIcon" size="18" />
                              </template>
                              <template #next-text>
                                 <feather-icon icon="ChevronRightIcon" size="18" />
                              </template>
                           </b-pagination>
                        </div>
                     </div>
                  </template>
               </vue-good-table>
            </div>
            <div v-else class="my-1 d-flex justify-content-center">
               <b-spinner variant="primary" label="Loading..." type="grow" />
            </div>
         </b-card>
      </div>
      <div v-else class="my-1 d-flex justify-content-center">
         <b-spinner variant="primary" label="Loading..." />
      </div>
   </section>
</template>

<script>
/* eslint-disable */
import {
   BRow,
   BPagination,
   BFormSelect,
   BCard,
   BCardBody,
   BOverlay,
   BBadge,
   BFormGroup,
   BFormInput,
   BPopover,
   BSpinner,
   VBTooltip,
   BAvatar,
   BCol,
   BButton,
   BFormSelectOption,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@/libs/axios";
import moment from "moment";
import Stars from "./components/StarsComponent.vue";

export default {
   components: {
      VueGoodTable,
      BPagination,
      BBadge,
      BOverlay,
      BCard,
      BCol,
      BButton,
      BCardBody,
      BFormGroup,
      BFormSelect,
      BFormInput,
      BSpinner,
      BPopover,
      Stars,
      BRow,
      BFormSelectOption,
      ToastificationContent,
      BAvatar,
   },
   directives: {
      "b-tooltip": VBTooltip,
   },
   data() {
      return {
         pageLength: 50,
         item: [],
         rows: [],
         searchTerm: "",
         stats: [],
         bonus: {},
         bonusStages: [
            { label: "Up to 50k", percent: 5, min: 0, max: 50000 },
            { label: "50k-100k", percent: 10, min: 50000, max: 100000 },
            { label: "Above 100k", percent: 15, min: 100000, max: Infinity },
         ],
         adminShare: [65, 25, 10],
         isLoading: false,
         thisMonth: null,
         selectedAdmin: null,
         newStatus: "",
         selectedRow: null,
         statusOptions: [
            { value: "pending", text: "Pending" },
            { value: "resolved", text: "Resolved" },
            { value: "refused", text: "Refused" },
         ],
         places: [
            { name: "first", label: "1st Place", percentage: 65 },
            { name: "second", label: "2nd Place", percentage: 25 },
            { name: "third", label: "3rd Place", percentage: 10 },
         ],
         topAdmins: [],
         confirmStatusChangeStep: false,
         sortOptions: {
            enabled: true,
            initialSortBy: [],
         },
         selectedFilter: 'all',
         ticketFilters: [
            { value: 'all', text: 'All Tickets' },
            { value: 'closed', text: 'Closed Tickets' },
            { value: 'custom', text: 'Tickets With Custom Development' },
         ],
         updateSecondaryStatus: {},
      };
   },
   computed: {
      user() {
         return this.$store.state.auth.userData;
      },
      tableData() {
         if (!this.selectedAdmin) return [];
         return this.sortAndOrderData(this.selectedAdmin.data);
      },
      columns() {
         const columns = [
            {
               label: "Support ticket",
               field: "ticket_id",
               sortable: false,
            },
            {
               label: "Team Member",
               field: "team_member",
               sortable: false,
            },
            {
               label: "Ticket Status",
               field: "ticket_status",
               sortable: false,
            },
            {
               label: "Status",
               field: "secondary_status",
               sortable: false,
            },
            {
               label: "Points",
               field: "points",
               sortable: true,
               sortFn: function (x, y, col, rowX, rowY) {
                  return x - y;
               },
            },
            {
               label: "Feature Name",
               field: "name",
               sortable: false,
            },
            {
               label: "Feature Price",
               field: "price",
               sortable: true,
               sortFn: function (x, y, col, rowX, rowY) {
                  return x - y;
               },
            },
            {
               label: "Admin Commission",
               field: "commission",
               sortable: true,
               sortFn: function (x, y, col, rowX, rowY) {
                  return x - y;
               },
            },
            {
               label: "Invoice ID",
               field: "invoice_id",
               sortable: false,
            },
            {
               label: "Paid This Month",
               field: "paid_this_month",
               sortable: false,
            }
         ];
         return columns;
      },
      prevMonths() {
         const currentMonth = moment().month() + 1; // Moment returns 0-based month

         return Array.from({ length: 12 }, (_, i) => {
            const month = moment().subtract(i, "month"); // Exclude the current month
            return {
               value: `${month.month() + 1}/${month.year()}`,
               name:
                  currentMonth === month.month() + 1
                     ? `This month (${month.format("MMMM")})`
                     : `${month.format("MMMM")} ${month.format("YYYY")}`,
            };
         });
      },
   },
   created() {
      this.fetchAdminsStatistics();
      this.thisMonth = `${moment().month() + 1}/${moment().year()}`;
   },
   methods: {
      handleSortChange(params) {
         this.sortOptions = params;
      },
      resetSort() {
         // Clear the sort options to remove any active sorting
         this.sortOptions = {
            enabled: true,
            initialSortBy: [], // Ensure no initial sorting is applied
         };

         // Optionally, you can reset the table's internal state by updating a key
         // This forces the table to re-render without sorting
         this.$refs.myTableKey += 1;
      },

      isCurrentStage(stage) {
         return this.bonus.total_amount >= stage.min && this.bonus.total_amount < stage.max;
      },

      isFutureStage(stage) {
         return this.bonus.total_amount < stage.min;
      },

      calculateBonus(amount) {
         const stage = this.bonusStages.find(s => amount >= s.min && amount <= s.max);

         if (!stage) {
            const highestStage = this.bonusStages[this.bonusStages.length - 1];
            return (amount * highestStage.percent) / 100;
         }

         return (amount * stage.percent) / 100;
      },
      statusTagColor(tag) {
         if (tag === 'waiting') return 'light-info'
         if (tag === 'onhold') return 'light-secondary'
         if (tag === 'in_progress') return 'light-warning'
         return 'light-primary'
      },
      formatCurrency(amount) {
         return parseFloat(amount).toLocaleString("en-US", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
         });
      },
      getStatusVariant(status, ticket_status) {
         const statusVariant = {
            pending: "warning",
            resolved: "success",
            refused: "danger",
         };
         //if ticket_status != closed always return warning
         if (ticket_status && ticket_status != 'closed' && ticket_status != 'permanent_closed') {
            return 'warning';
         }
         return statusVariant[status] || "success";
      },
      askConfirmStatusChange(row, status) {
         this.selectedRow = row;
         this.newStatus = status;
         this.confirmStatusChangeStep = true;
      },
      clearFilters() {
         this.isLoading = true;
         this.thisMonth = `${moment().month() + 1}/${moment().year()}`;
         this.fetchAdminsStatistics();
         this.resetSort();
         this.selectedFilter = 'all';
         this.isLoading = false;
      },
      confirmStatusChange() {
         if (this.selectedRow) {
            const payload = {
               action: "update-status",
               ticketId: this.selectedRow.ticket_id,
               status: this.newStatus,
            };

            this.updateSecondaryStatus[this.selectedRow.ticket_id] = true;

            axios
               .post(`/api/tickets/update-admins-statistics`, payload)
               .then((response) => {
                  if (response.status === 200) {
                     // Update the row's status
                     this.selectedRow.secondary_status = this.newStatus;

                     // Show success toast
                     this.$toast({
                        component: ToastificationContent,
                        props: {
                           title: "Status Updated",
                           icon: "CheckIcon",
                           text: `Status changed to ${this.newStatus}.`,
                           variant: "success",
                        },
                     });

                     // Refetch the statistics to update the data
                     this.fetchAdminsStatistics();
                  }
                  this.updateSecondaryStatus[this.selectedRow.ticket_id] = false;
               })
               .catch((error) => {
                  console.error("Error updating status:", error);
                  this.$toast({
                     component: ToastificationContent,
                     props: {
                        title: "Error",
                        icon: "AlertTriangleIcon",
                        text: "There was an error updating the status.",
                        variant: "danger",
                     },
                  });
                  this.updateSecondaryStatus[this.selectedRow.ticket_id] = false;
               });
         }
         this.confirmStatusChangeStep = false;
      },

      cancelStatusChange() {
         this.confirmStatusChangeStep = false;
         this.selectedRow = null;
      },

      fetchAdminsStatistics() {
         axios
            .post("/api/tickets/admins-statistics", {
               dateMonth: this.thisMonth,
            })
            .then((response) => {
               this.stats = response.data.stats;
               this.bonus = response.data.bonus;
               const allItems = this.stats.flatMap((stat) =>
                  stat.data.map((item) => ({
                     ...item,
                     admin: stat.admin,
                  }))
               );

               this.selectedAdmin = {
                  data: allItems,
               };

               this.calculateTopAdmins();
            })
            .catch((error) => {
               console.log(error);
            });
      },

      sortAndOrderData(allItems) {
         if (this.selectedFilter === 'closed') {
            allItems = allItems.filter((item) => item.ticket_status === 'closed' || item.ticket_status === 'permanent_closed');
         } else if (this.selectedFilter === 'custom') {
            allItems = allItems.filter((item) => item.commission > 0);
         }

         const pendingItems = allItems.filter(
            (item) => item.secondary_status === 'pending'
         );
         const otherItems = allItems.filter(
            (item) => item.secondary_status !== 'pending'
         );

         const sortedOtherItems = otherItems.sort((a, b) => {
            // Compare ticket_status
            if (a.ticket_status === 'closed' && b.ticket_status !== 'closed') {
               return -1; // a comes before b
            }
            if (a.ticket_status !== 'closed' && b.ticket_status === 'closed') {
               return 1; // b comes before a
            }

            // If both have the same ticket_status, sort by ticket_id descending
            if (typeof a.ticket_id === 'number' && typeof b.ticket_id === 'number') {
               return b.ticket_id - a.ticket_id; // Descending order
            } else {
               // For string ticket_ids, use localeCompare and reverse the result for descending
               return String(b.ticket_id).localeCompare(String(a.ticket_id));
            }
         });

         return [...pendingItems, ...sortedOtherItems];
      },


      calculateTopAdmins() {
         this.topAdmins = [...this.stats]
            .sort((a, b) => b.total_points - a.total_points)
            .slice(0, 3)
            .map((admin, index) => ({
               admin: admin.admin,
               share: this.places[index].percentage,
            }));
      },

      calculateAdminBonus(index) {
         if (index > 2) return 0;
         const bonusShare = this.adminShare[index];
         const adminBonus = (this.bonus.bonus_amount * bonusShare) / 100;
         return adminBonus;
      },

      computeTotalComission(customIntegrations, bonus) {
         return (parseFloat(customIntegrations) + parseFloat(bonus)).toFixed(2);
      },

      monthChange() {
         this.stats = [];
         this.selectedAdmin = null;
         this.fetchAdminsStatistics();
      },

      openStatistics(id) {
         this.isLoading = true;
         // Find the statistics for the clicked admin
         const adminStats = this.stats.find((stat) => stat.admin.id === id);


         if (adminStats) {
            let adminData = adminStats.data.map((item) => ({
               ...item,
               admin: adminStats.admin,
            }))
            this.selectedAdmin = {
               data: adminData
            };
         } else {
            this.selectedAdmin = null;
         }

         if (!this.selectedAdmin || !this.selectedAdmin.data.length) {
            this.$toast({
               component: ToastificationContent,
               props: {
                  title: "No data",
                  icon: "AlertTriangleIcon",
                  text: "No data available for this admin.",
                  variant: "warning",
               },
            });
            this.selectedAdmin = null;
         }

         setTimeout(() => {
            this.isLoading = false;
         }, 300);
      },

      getElapseDate(Date) {
         return moment(Date).fromNow();
      },

      sortFnDate(x, y, col, rowX, rowY) {
         const value1 = Date.parse(this.filterFormatDate(rowX.created_at));
         const value2 = Date.parse(this.filterFormatDate(rowY.created_at));

         return value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
      },

      filterFormatDate(date) {
         const d = new Date(date);
         let month = `${d.getMonth() + 1}`;
         let day = `${d.getDate()}`;
         const year = d.getFullYear();
         if (month.length < 2) month = `0${month}`;
         if (day.length < 2) day = `0${day}`;
         return [year, month, day].join("-");
      },
   },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.home-wrapper div.col-sm-6.col-lg-3 a {
   color: #6e6b7b;
}

.mx-datepicker {
   width: 100% !important;
}

.invoice-register-table {
   thead tr {
      background: #f3f2f7;
   }

   th,
   td {
      font-size: 14px;
      text-align: center !important;
      vertical-align: middle !important;
   }

   td {
      height: 62px;
   }
}

.ir-btn-wrapper {
   width: 30rem;

   .ir-btn {
      width: 100%;
      max-width: 148px;
   }
}

.activation-btn {
   width: 100%;
   max-width: 16rem;
}

.filter-th {
   display: none;
}

.vgt-global-search {
   display: none;
}

.mobileVersion {
   display: none;
}

/* iphone 12/13 pro max - android pro max*/
@media only screen and (max-width: 428px) {
   .header-navbar.floating-nav {
      width: 86% !important;
      margin-right: 47px !important;
   }

   .upper-select1,
   .upper-select2,
   .upper-select3 {
      margin-top: 5px;
      width: 352px !important;
   }

   .custom-search {
      float: left !important;
   }

   .mytable {
      width: 100% !important;
      z-index: 0;
   }

   .upper-select {
      position: relative;
      z-index: 1;
   }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 415px) {
   .header-navbar.floating-nav {
      width: 83% !important;
      margin-right: 60px !important;
   }

   .upper-select1,
   .upper-select2,
   .upper-select3 {
      margin-top: 5px;
      width: 337px !important;
   }

   .custom-search {
      float: left !important;
   }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 395px) {
   .header-navbar.floating-nav {
      width: 78% !important;
      margin-right: 83px !important;
   }

   .upper-select1,
   .upper-select2,
   .upper-select3 {
      margin-top: 5px;
      width: 307px !important;
   }

   .custom-search {
      float: left !important;
   }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 376px) {
   .header-navbar.floating-nav {
      width: 76% !important;
      margin-right: 96px !important;
   }

   .upper-select1,
   .upper-select2,
   .upper-select3 {
      margin-top: 5px;
      width: 299px !important;
   }

   .custom-search {
      float: left !important;
   }
}

/* smaller phone */
@media only screen and (max-width: 365px) {
   .header-navbar.floating-nav {
      width: 91% !important;
      margin-right: 16px !important;
   }

   .upper-select1,
   .upper-select2,
   .upper-select3 {
      margin-top: 5px;
      width: 285px !important;
   }

   .custom-search {
      float: left !important;
   }
}

.dark-layout a,
.dark-layout .badge.badge-light-primary {
   color: #fb50a1 !important;
}

@media only screen and (max-width: 768px) {
   .mobileVersion {
      display: block;

      input,
      select {
         width: 20rem !important;
         margin: 5px auto;
      }
   }

   .desktopVersion {
      display: none;
   }

   .mytable {
      margin-top: 0px !important;
   }
}

.text-noir {
   color: #7c818e !important
}

.bold {
   font-weight: bold !important;
}

.text-white {
   color: #fff !important;
}
</style>
